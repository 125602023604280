<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="修改用户"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="3">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">用户信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="21">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.username">
                        <template slot="prepend">用户名</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.email">
                        <template slot="prepend">邮箱</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.password">
                        <template slot="prepend">密码</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                  <el-select v-model="form.admin_level" placeholder="请选择">
                    <el-option
                      v-for="item in admin_levels"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10">
                  <el-col :span="6">
                      <el-input placeholder="请输入内容" v-model="form.total_amount" disabled>
                        <template slot="prepend">累计充值</template>
                      </el-input>
                  </el-col>
                  <el-col :span="6">
                      <el-input placeholder="请输入内容" v-model="form.discount">
                        <template slot="prepend">折扣</template>
                      </el-input>
                  </el-col>
                  <el-col :span="8">
                      <el-input placeholder="请输入内容" v-model="form.prepaid_amount">
                        <template slot="prepend">预付余额</template>
                      </el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button @click="dialogVisible2 = true">充值</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
          <!-- 消费记录 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="3">
                <div class="part-info">
                  <div class="title">充值记录</div>
                  <div class="info">充值</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="21">
                <el-table
                  :data="logs"
                  height="600px"
                  style="width: 100%">
                  <el-table-column
                    label="金额"
                    width="100">
                      <template slot-scope="scope">
                        {{scope.row.method == 1 ? '充值':'消费'}} {{scope.row.amount}}
                      </template>
                  </el-table-column>
                  <el-table-column
                    prop="create_time_str"
                    label="交易时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="comment"
                    label="备注"
                    >
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>账号状态</span>
            </div>
            <p class="p-extra mb5">账号启用状态</p>
            <el-select v-model="form.status" placeholder="请选择账号状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 添加商品表单结束 -->

    <!-- 充值 -->
    <el-dialog
      :append-to-body="true"
      title="充值"
      :visible.sync="dialogVisible2"
      width="30%">
        <el-form ref="dialogVisible2" :model="rechargeForm" label-width="100px">
          <el-form-item label="修改类型">
            <el-radio-group v-model="rechargeForm.recharge_type">
              <el-radio label="add">增加</el-radio>
              <el-radio label="reduce">减少</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="激活码数量">
              <el-input-number v-model="rechargeForm.change_amount" :precision="2" :min="1" :step="0.1" :max="9999" clearable></el-input-number>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="rechargeAdmin">确认充值</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        rechargeForm:{change_amount:0,recharge_type:'add'},
        dialogVisible2:false,
        form:{},
        logs:[],
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
          {name:'所有',value:null},
        ],
        devices_list:[],
        admin_levels:[
          {label:'无权限', value:0},
          {label:'代理', value:1},
          {label:'最高权限', value:9},
        ]
      }
    },
    methods:{
      save(){
        let p = this.$route.params.obj;
        if(!p) return;
        this.form.admin_id = p.id
        apis.admin.update(this.form).then(res=>{
          if(res.data.code == 1){
            this.$message({message:'修改成功', type:'success'})
            this.$router.push({name:'admin'})
          }
        }).catch(err=>{
          this.$message({message:err,type:'error'})
          console.log('err',err)
        })
      },
      readOneAdmin(){
        let p = this.$route.params.obj;
        if(!p) return;
        apis.admin.readOneAdmin({admin_id:p.id}).then(res=>{
          console.log('res',res)
          // this.form_loading = false;
          this.form = res.data.data
          // this.devices_list = res.data.data.devices
          this.readLogs()
        }).catch(err=>{
          this.form_loading = false;
          console.log('err',err)
        })
      },
      readLogs(id){
        apis.payment.logs({set_id:this.form.id,payment_user:'admin'}).then(res=>{
          this.logs = res.data.data
        }).catch(err=>{
        })
      },
      rechargeAdmin(){
        this.rechargeForm.admin_id = this.form.id
        apis.payment.rechargeAdmin(this.rechargeForm).then(res=>{
          let r = res.data
          if(r.code == 1){
            this.$message({message:r.msg,type:'success'})
            this.readOneAdmin()
          }else{
            this.$message({message:r.msg,type:'error'})
          }
          this.dialogVisible2 = false
        }).catch(err=>{
          this.dialogVisible2 = false
        })
      }
    },
    mounted(){
        this.readOneAdmin()
    },
    computed:{

    },
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>